import { StatusBar } from 'expo-status-bar';
import React, { useContext, useEffect, useState, ErrorInfo, ReactNode } from 'react';
import { StyleSheet, Text, View, Image, TextInput, Button, TouchableOpacity } from 'react-native';
import { withSafeAreaInsets } from 'react-native-safe-area-context';
import { AuthContext } from '../contexts/Auth';
import { useNavigate } from 'react-router-dom';
import { LinearGradient } from 'expo-linear-gradient';
import { Box, HStack, Spinner } from 'native-base';
import { Consts } from '../constants/Consts';
import { flexbox } from 'native-base/lib/typescript/theme/styled-system';
import { useThemeColors, useThemeImages, useThemedComponents, useThemedAjusts } from '../components/Themed';
import { ColorNames, ImageNames, ThemedComponentType, ThemedAjustType } from '../types';

export default function App() {
    const Colors: ColorNames = useThemeColors();
    const Images: ImageNames = useThemeImages();
    const ThemedComponent: ThemedComponentType = useThemedComponents();
    const Ajusts: ThemedAjustType = useThemedAjusts();

    const [userId, setUserId] = useState('');
    const [loading, setLoading] = useState(false);
    const { isAuth, login, loginErrorMessage } = useContext(AuthContext);
    const navigate = useNavigate();

    //クエリパラメータログインチェック
    const url = new URL(window.location.href);
    const id_value = url.searchParams.get('id');
    React.useEffect(() => {
        if (isAuth) {
            setLoading(false);
            navigate('/posts');
        }
    }, [isAuth]);

    React.useEffect(() => {
        if (id_value) {
            login(id_value);
        }
    }, [id_value]);

    const onPressLogin = () => {
        if (!loading) {
            login(userId);
            setLoading(true);
        }
    };

    React.useEffect(() => {
        if (loginErrorMessage) {
            setLoading(false);
        }
    }, [loginErrorMessage]);

    const styles = StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: Colors.white,
            alignItems: 'center',
            justifyContent: 'center',
            paddingRight: 20,
            paddingLeft: 20
        },
        image: {
            marginBottom: 52,
            width: '275px',
            height: '96px'
        },
        loginTitleBox: {
            width: '25%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: Colors.lightGray,
            borderColor: Colors.mediumGray,
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomLeftRadius: 50,
            borderTopLeftRadius: 50
        },
        loginTitle: {
            fontSize: 12,
            fontWeight: '600'
        },
        loginTextBox: {
            height: '100%',
            width: '75%',
            justifyContent: 'center',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderColor: Colors.mediumGray,
            borderBottomRightRadius: 50,
            borderTopRightRadius: 50
        },
        loginTextInput: {
            height: '100%',
            padding: 10,
            borderBottomRightRadius: 50,
            borderTopRightRadius: 50
        },
        loginButtonText: {
            color: Colors.white,
            fontWeight: 'bold'
        },
        loginButton: {
            maxWidth: '335px',
            width: '100%',
            borderRadius: 50,
            height: 50,
            alignItems: 'center',
            justifyContent: 'center'
        },
        errorText: {
            color: Colors.error,
            marginTop: 10
        },
        applicationTextBox: {
            paddingTop: '20px'
        },
        youtube: {
            marginTop: '30px',
            display: Ajusts.Display
        },
        applicationText: {
            textAlign: 'center',
            fontSize: 18,
            fontWeight: '800'
        },
        titleTxt: {
            fontWeight: 'bold',
            color: Colors.primary,
            fontSize: 20,
            paddingTop: '20px',
            textAlign: 'center'
        },
        titlePeriod: {
            fontSize: Ajusts.LoginPeriodFontSize,
            paddingTop: '20px'
        }
    });

    return (
        <View style={styles.container}>
            <ThemedComponent.LoginImage />

            {/* <StatusBar style="auto" /> */}

            <HStack flexDirection="row" marginBottom={15} maxWidth="335px" width="100%" height="46px">
                <Box style={styles.loginTitleBox}>
                    <Text style={styles.loginTitle}>ログインID</Text>
                </Box>
                <Box style={styles.loginTextBox}>
                    <TextInput
                        style={styles.loginTextInput}
                        placeholder="IDを入力してください"
                        placeholderTextColor={Colors.lightGray}
                        secureTextEntry={false}
                        onChangeText={(userId) => setUserId(userId)}
                    />
                </Box>
            </HStack>

            <TouchableOpacity disabled={loading} onPress={() => onPressLogin()} style={styles.loginButton}>
                <LinearGradient
                    colors={
                        !loading
                            ? [Colors.primaryGradientStart, Colors.primaryGradientEnd]
                            : [Colors.lightGray, Colors.lightGray]
                    }
                    start={[0, 0]}
                    end={[1, 0]}
                    style={styles.loginButton}
                >
                    {loading ? (
                        <Spinner color={Colors.primary} />
                    ) : (
                        <Text style={styles.loginButtonText}>ログインする</Text>
                    )}
                </LinearGradient>
            </TouchableOpacity>
            {loginErrorMessage ? <Text style={styles.errorText}>{loginErrorMessage}</Text> : null}

            <Box style={styles.youtube}>
                <video
                    width="300px"
                    height="170px"
                    controls
                    preload="none"
                    controlsList="nodownload"
                    poster={require('../assets/images/contest_site_login_screen_video_thumbnail.jpg')}
                    src="https://firebasestorage.googleapis.com/v0/b/am-doyu-contest.appspot.com/o/all%2Fothers%2Fvideos%2Fcontest_post_process_2024second_half.mp4?alt=media&token=e2c16f77-a93d-44aa-90dc-207a99a70389"
                ></video>
            </Box>

            {/* <Box style={styles.youtube}>
                <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/rpsnIyoYO5M?rel=0"
                    title="VPコンテスト2022Renewal概要"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </Box> */}
            <Text style={styles.titleTxt}>{Ajusts.LoginTitle}</Text>
            <Text style={styles.titlePeriod}>{Ajusts.LoginPeriod}</Text>
        </View>
    );
}
